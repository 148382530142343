import { graphql, Link } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogArchive = props => {
  const { data, pageContext } = props
  const location = props.location
  const siteTitle = data.site?.siteMetadata?.title || `Title`
  const posts = data.allMdx.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <ol style={{ listStyle: `none`, padding: `0 var(--spacing-4)` }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (
            <li key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header className="post-list-item__header">
                  <small className="post-list-item__header-published">
                    {post.frontmatter.date}
                  </small>
                  <h2 className="post-list-item__header-headline">
                    <Link to={post.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                </header>
                <section className="post-list-item__section">
                  <p
                    itemProp="description"
                    className="post-list-item__description"
                  >
                    {post.excerpt || post.frontmatter.description}
                  </p>
                </section>
              </article>
            </li>
          )
        })}
      </ol>

      <nav
        style={{
          padding: `0 var(--spacing-4)`,
        }}
      >
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {pageContext.previousPagePath && (
              <Link to={pageContext.previousPagePath} rel="prev">
                ← {pageContext.humanPageNumber - 1}
              </Link>
            )}
          </li>
          <li>{pageContext.humanPageNumber}</li>
          <li>
            {pageContext.nextPagePath && (
              <Link to={pageContext.nextPagePath} rel="next">
                {pageContext.humanPageNumber + 1} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        excerpt(truncate: true, pruneLength: 90)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY年MM月DD日")
          title
          description
        }
      }
    }
  }
`

export default BlogArchive

export const Head = () => <Seo />
